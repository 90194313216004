<template>
  <b-card class="profile-card  mb-5">
    <b-row align-v="center">
      <b-col
        class="icon-section"
        cols="auto"
      >
        <feather-icon
          icon="FileIcon"
          size="24"
          class="text-primary"
        />
      </b-col>
      <b-col class="details border-left">
        <h4>{{ $t('src.views.pages.inside.account-setting.components.companydocuments.companyD') }}</h4>
        <div v-show="!editMode">
          <p>
            <b-link v-b-modal.ssm>
              {{ $t('src.views.pages.inside.account-setting.components.companydocuments.ssmD') }}
            </b-link>
          </p>
          <p />
        </div>
        <div
          v-show="editMode"
        >
          <validation-observer
            ref="companyDocuObserver"
            class="d-flex flex-column justify-content-center mt-1 mr-0"
          >
            <validation-provider
              #default="{ errors }"
              name="SSM Document"
              class="mb-1 mr-0"
              rules="checkSSMFile"
              mode="passive"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-file
                  id="ssm"
                  v-model="form.ssm"
                  :placeholder="$t('src.views.pages.inside.account-setting.components.companydocuments.chooseSD')"
                  :drop-placeholder="$t('src.views.pages.inside.account-setting.components.companydocuments.dropFH')"
                  accept=".pdf"
                />
              </b-input-group>
              <small
                id="check-validity-ssm"
                class="text-info d-none"
              >{{ $t('src.views.pages.inside.account-setting.components.companydocuments.checkingV') }}</small>
              <small
                id="ssm-error"
                class="text-danger"
              >{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </div>
      </b-col>
      <b-col
        class="edit-section"
        cols="auto"
      >
        <a
          v-if="!editMode"
          href="#"
          @click.prevent="editFields"
        >{{ $t('src.views.pages.inside.account-setting.components.companydocuments.edit') }}</a>
        <a
          v-else-if="editMode"
          href="#"
          @click.prevent="saveFields"
        >{{ $t('src.views.pages.inside.account-setting.components.companydocuments.save') }}</a>
      </b-col>
    </b-row>
    <b-modal
      id="ssm"
      :title="$t('src.views.pages.inside.account-setting.components.companydocuments.ssm')"
      ok-only
      :ok-title="$t('src.views.pages.inside.account-setting.components.companydocuments.close')"
      size="lg"
    >
      <b-card-text>
        <div
          class="container"
          style="min-height:600px;"
        >
          <iframe
            style="position: absolute;width: calc(100% - 65px);height: 100%;border: none;"
            :src="ssm"
          />
        </div>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import axios from '@axios'

const checkSSMFile = async field => {
  const checkMessage = document.getElementById('check-validity-ssm')
  const ssmError = document.getElementById('ssm-error')
  const formData = new FormData()
  formData.append('ssm', field)
  checkMessage.classList.remove('d-none')
  ssmError.classList.add('d-none')
  const response = await axios({
    method: 'post',
    url: '/checkDocuments',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  if (response?.data?.status) {
    checkMessage.classList.add('d-none')
    ssmError.classList.remove('d-none')
    return true
  }
  checkMessage.classList.add('d-none')
  ssmError.classList.remove('d-none')
  return 'The SSM Document is not valid'
}
extend('checkSSMFile', checkSSMFile)
export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    ssmFile: {
      type: String,
      default: '',
    },
    updateDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        ssm: null,
      },
      ssm: this.ssmFile,
      editMode: false,
      required,
    }
  },
  watch: {
    ssmFile(val) {
      this.ssm = val
      // pdf.createLoadingTask(this.getFilePath(this.userInfo.smart_ssm_document))
      // this.ssm.promise.then(test => {
      //   this.numPages = test.numPages
      // })
    },
  },
  methods: {
    editFields() {
      this.editMode = !this.editMode
    },
    saveFields() {
      this.$refs.companyDocuObserver.validate().then(async success => {
        if (success) {
          this.$emit('update-details', this.form)
        }
      })
    },
  },
}
</script>
