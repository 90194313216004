<template>
  <b-card class="profile-card">
    <b-row align-v="center">
      <b-col
        class="icon-section"
        cols="auto"
      >
        <feather-icon
          icon="PhoneIcon"
          size="24"
          class="text-primary"
        />
      </b-col>
      <b-col class="details border-left">
        <h4>{{ $t('src.views.pages.inside.account-setting.components.phonenumber.phoneN') }}</h4>
        <div v-show="!editMode">
          <p>{{ phoneNumber }}</p>
        </div>
        <div
          v-show="editMode"
          class="edit-input"
        >
          <validation-observer ref="phoneObserver">
            <validation-provider
              #default="{ errors }"
              name="phone"
              rules="required|integer"
            >
              <b-form-input
                id="phoneNumber"
                ref="phoneNumber"
                v-model="form.phoneNumber"
                :state="errors.length > 0 ? false:null"
                name="phone"
                :placeholder="$t('src.views.pages.inside.account-setting.components.phonenumber.enterIPN')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </div>
      </b-col>
      <b-col
        class="edit-section"
        cols="auto"
      >
        <a
          v-if="!editMode"
          href="#"
          @click.prevent="editFields"
        >{{ $t('src.views.pages.inside.account-setting.components.phonenumber.edit') }}</a>
        <a
          v-else-if="editMode"
          href="#"
          @click.prevent="saveFields"
        >{{ $t('src.views.pages.inside.account-setting.components.phonenumber.save') }}</a>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import { integer } from 'vee-validate/dist/rules'

extend('integer', {
  ...integer,
  message: 'Please enter numbers only',
})

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
    officeNumber: {
      type: String,
      default: '',
    },
    mobileNumber: {
      type: String,
      default: '',
    },
    updateDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        phoneNumber: this.phoneNumber,
      },
      editMode: false,
      required,
    }
  },
  watch: {
    phoneNumber(val) {
      this.form.phoneNumber = val
    },
  },
  methods: {
    editFields() {
      this.editMode = !this.editMode
      this.$nextTick(() => {
        this.$refs.phoneNumber.focus()
      })
    },
    saveFields() {
      console.log(this.form)
      this.$refs.phoneObserver.validate().then(async success => {
        if (success) {
          this.$emit('update-details', this.form)
        }
      })
    },
  },
}
</script>
