<template>
  <div class="mx-5 px-1">
    <b-overlay :show="isLoading">
      <div v-if="!isLoading">
        <Company
          v-if="!isLoading"
          :company-name="userInfo.name"
          :company-number="userInfo.company_registration"
          :image-path="userInfo.imagePath"
          @update-details="updateDetails"
        />
        <NameAndEmail
          :full-name="defaultAddress.name"
          :user-email="userInfo.email"
          @update-details="updateDetails"
        />
        <Address
          :addresses="addresses"
          :remove-address="removeAddress"
          :full-name="defaultAddress.name"
          :phone-number="userInfo.phone"
          :default-id="defaultAddressId"
          :get-updated-user="getUpdatedUser"
          @update-details="updateDetails"
        />
        <PhoneNumber
          :phone-number="userInfo.phone"
          @update-details="updateDetails"
        />
        <BusinessAndProduct
          :business-type="userInfo.business_type"
          @update-details="updateDetails"
        />
        <CompanyDocuments
          v-if="!isLoading"
          :ssm-file="userInfo.ssmPath"
          :cdic-file="userInfo.cdicPath"
          @update-details="updateDetails"
        />
        <!-- <b-container class="text-center">
      <b-button
        type="button"
        variant="primary"
        class="mt-3 mb-5"
        to="/"
      >{{ $t('src.views.pages.inside.account-setting.accountsetting.backTD') }}</b-button>
      </b-container> -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import {
  Company, NameAndEmail, Address, PhoneNumber, BusinessAndProduct, CompanyDocuments,
} from './components'

export default {
  name: 'AccountSetting',
  components: {
    Company,
    NameAndEmail,
    Address,
    PhoneNumber,
    BusinessAndProduct,
    CompanyDocuments,
  },
  data() {
    return {
      userInfo: [],
      addresses: [],
      defaultAddress: [],
      defaultAddressId: null,
      isLoading: false,
    }
  },
  mounted() {
    this.getUpdatedUser()
  },
  methods: {
    setAddresses() {
      this.addresses = this.userInfo.addresses
      this.defaultAddressId = this.userInfo.default_address.id
      this.defaultAddress = this.addresses.find(address => address.id === this.defaultAddressId)
    },
    async updateDetails(data) {
      if ('fullName' in data) {
        const updatedAddress = this.userInfo.addresses
          .map(address => ({
            ...address,
            ...{ name: address.id === this.defaultAddressId ? data.fullName : address.name },
          }))
        this.userInfo.addresses = updatedAddress
      }
      const updatedData = { ...this.getCurrentData(), ...data }
      try {
        const formData = new FormData()
        Object.keys(updatedData).forEach(key => {
          if (key === 'addresses') {
            Object.keys(updatedData[key]).forEach(addresskey => {
              Object.keys(updatedData[key][addresskey]).forEach(fieldkey => {
                formData.append(`addresses[${addresskey}][${fieldkey}]`, updatedData[key][addresskey][fieldkey])
              })
            })
            // formData.append(key, JSON.stringify(updatedData[key]))
          } else {
            formData.append(key, updatedData[key])
          }
        })
        const response = await axios.post('/updateUser', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        const { data: responseData } = response
        if (responseData) {
          this.updateUserInfo(updatedData)
          this.getUpdatedUser()
          return 'success'
        }
        return 'error'
      } catch (error) {
        console.log(error)
        return 'error'
      }
    },
    async removeAddress(addressId) {
      try {
        const response = await this.$http.post('/removeAddress', { address_id: addressId })
        const { status } = response
        if (status === 200) {
          this.getUpdatedUser()
          return 'success'
        }
        return 'error'
      } catch (error) {
        console.log(error)
        return 'error'
      }
    },
    getCurrentData() {
      return {
        companyName: this.userInfo.name,
        companyNumber: this.userInfo.company_registration,
        email: this.userInfo.email,
        addresses: this.userInfo.addresses,
        phoneNumber: this.userInfo.phone,
        business_type: this.userInfo.business_type,
        ssm: this.userInfo.smart_ssm_document,
        cdic: this.userInfo.cdic,
      }
    },
    updateUserInfo(updatedData) {
      this.userInfo = {
        ...this.userInfo,
        ...{
          name: updatedData.companyName,
          company_registration: updatedData.companyNumber,
          email: updatedData.email,
          addresses: updatedData.addresses,
          phone: updatedData.phoneNumber,
        },
      }
      this.defaultAddress = { ...this.defaultAddress, name: updatedData.fullName ?? this.defaultAddress.name }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('edit_successful'),
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      })
    },
    async getUpdatedUser() {
      this.isLoading = true
      const userData = await this.$http.get('/userData')
      this.userInfo = userData.data.data
      this.userInfo.imagePath = this.getFilePath(this.userInfo.pic)
      this.userInfo.cdicPath = this.getFilePath(this.userInfo.cdic)
      this.userInfo.ssmPath = this.getFilePath(this.userInfo.smart_ssm_document)
      this.setAddresses()
      this.isLoading = false
    },
    getFilePath(image) {
      return `${this.$s3URL}uploads/user/${image}`
    },
  },

}
</script>
