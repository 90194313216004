<template>
  <b-card class="profile-card mb-5">
    <b-row align-v="center">
      <b-col class="upload-photo">
        <b-avatar
          :src="image"
          size="12rem"
          variant="primary"
        />
        <input
          ref="fileInput"
          type="file"
          style="display: none"
          accept="image/*"
          @change="onFilePicked"
        >
        <input
          ref="fileInput"
          type="file"
          style="display: none"
          accept="image/*"
          @change="onFilePicked"
        >
        <div class="upload-button-container">
          <b-button
            variant="gradient-danger"
            class="btn-icon rounded-circle"
            @click="onPickFile"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
      </b-col>
      <b-col
        class="avatar-spacer"
        cols="auto"
      />
      <b-col class="details">
        <div v-show="!editMode">
          <h4>{{ companyName.toUpperCase() }}</h4>
          <p>{{ companyNumber }}</p>
        </div>
        <div
          v-show="editMode"
        >
          <validation-observer ref="companyForm">
            <validation-provider
              #default="{ errors }"
              name="company name"
              rules="required"
            >
              <b-form-input
                id="companyName"
                ref="companyName"
                v-model="form.companyName"
                :placeholder="$t('src.views.pages.inside.account-setting.components.company.enterCN')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="company registration number"
              rules="required"
            >
              <b-form-input
                id="registrationNumber"
                ref="registrationNumber"
                v-model="form.companyNumber"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('src.views.pages.inside.account-setting.components.company.enterCRN')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </div>
      </b-col>
      <b-col
        class="edit-section"
        cols="auto"
      >
        <a
          v-if="!editMode"
          href="#"
          @click.prevent="editFields"
        >{{ $t('src.views.pages.inside.account-setting.components.company.edit') }}</a>
        <a
          v-else-if="editMode"
          href="#"
          @click.prevent="saveFields"
        >{{ $t('src.views.pages.inside.account-setting.components.company.save') }}</a>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard } from 'bootstrap-vue'
import axios from '@axios'

export default {
  name: 'Company',
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    companyName: {
      type: String,
      default: '',
    },
    companyNumber: {
      type: String,
      default: '',
    },
    imagePath: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      options: {},
      form: {
        companyName: this.companyName,
        companyNumber: this.companyNumber,
      },
      image: this.imagePath,
      editMode: false,
      required,
    }
  },
  watch: {
    companyName(val) {
      this.form.companyName = val
    },
    companyNumber(val) {
      this.form.companyNumber = val
    },
    imagePath(val) {
      this.image = val
    },
  },
  methods: {
    editFields() {
      this.editMode = !this.editMode
      this.$nextTick(() => {
        this.$refs.companyName.focus()
      })
    },
    async saveFields() {
      this.$refs.companyForm.validate().then(async success => {
        if (success) {
          this.$emit('update-details', this.form)
        }
      })
    },
    onPickFile() {
      this.$refs.fileInput.click()
    },
    onFilePicked(event) {
      const { files } = event.target
      const reader = new FileReader()
      const formData = new FormData()
      formData.append('pic', files[0])
      reader.readAsDataURL(files[0])
      reader.onload = async e => {
        this.image = e.target.result
        await axios.post('/updateUserImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload Photo Successful!',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
      }
    },
  },
}
</script>
