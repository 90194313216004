<template>
  <b-card class="profile-card">
    <b-row align-v="center">
      <b-col
        class="icon-section"
        cols="auto"
      >
        <feather-icon
          icon="BriefcaseIcon"
          size="24"
          class="text-primary"
        />
      </b-col>
      <b-col class="details border-left">
        <h4>{{ $t('src.views.pages.inside.account-setting.components.businessandproduct.natureOB') }}</h4>
        <div
          style="width:30%"
        >
          <validation-observer
            ref="businessObserver"
            class="d-flex flex-column justify-content-center mt-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Business Nature"
              rules="required"
            >
              <b-form-group class="mb-0">
                <b-form-radio-group
                  v-model="form.business_type"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <div class="d-flex justify-content-between">
                    <b-form-radio
                      plain
                      name="business_type"
                      :class="!editMode ? 'disabled-radio' : ''"
                      value="1"
                      :disabled="!editMode ? true : false"
                    >{{ $t('src.views.pages.inside.account-setting.components.businessandproduct.bb') }}</b-form-radio>
                    <b-form-radio
                      plain
                      name="business_type"
                      :class="!editMode ? 'disabled-radio' : ''"
                      value="2"
                      :disabled="!editMode ? true : false"
                    >{{ $t('src.views.pages.inside.account-setting.components.businessandproduct.bc') }}</b-form-radio>
                    <b-form-radio
                      plain
                      name="business_type"
                      :class="!editMode ? 'disabled-radio' : ''"
                      value="3"
                      :disabled="!editMode ? true : false"
                    >{{ $t('src.views.pages.inside.account-setting.components.businessandproduct.bbc') }}</b-form-radio>
                  </div>
                </b-form-radio-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </div>
      </b-col>
      <b-col
        class="edit-section"
        cols="auto"
      >
        <a
          v-if="!editMode"
          href="#"
          @click.prevent="editFields"
        >{{ $t('src.views.pages.inside.account-setting.components.businessandproduct.edit') }}</a>
        <a
          v-else-if="editMode"
          href="#"
          @click.prevent="saveFields"
        >{{ $t('src.views.pages.inside.account-setting.components.businessandproduct.save') }}</a>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    businessType: {
      type: Number,
      default: 0,
    },
    updateDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        business_type: this.businessType,
      },
      editMode: false,
      required,
    }
  },
  watch: {
    businessType(val) {
      this.form.business_type = val
    },
  },
  methods: {
    editFields() {
      this.editMode = !this.editMode
    },
    saveFields() {
      this.$refs.businessObserver.validate().then(async success => {
        if (success) {
          this.$emit('update-details', this.form)
        }
      })
    },
  },
}
</script>
