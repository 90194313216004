const STATE = [
  { text: 'Kuala Lumpur', value: 'Kuala Lumpur' },
  { text: 'Perlis', value: 'Perlis' },
  { text: 'Kedah', value: 'Kedah' },
  { text: 'Pulau Pinang', value: 'Pulau Pinang' },
  { text: 'Perak', value: 'Perak' },
  { text: 'Pahang', value: 'Pahang' },
  { text: 'Kelantan', value: 'Kelantan' },
  { text: 'Terengganu', value: 'Terengganu' },
  { text: 'Selangor', value: 'Selangor' },
  { text: 'Negeri Sembilan', value: 'Negeri Sembilan' },
  { text: 'Melaka', value: 'Melaka' },
  { text: 'Johor', value: 'Johor' },
  { text: 'Sabah', value: 'Sabah' },
  { text: 'Sarawak', value: 'Sarawak' },
  { text: 'Singapore', value: 'Singapore' },
]

const MARKETS = [
  { value: 0, label: 'Hypermarket' },
  { value: 1, label: 'Convenience Store' },
  { value: 2, label: 'Local Business' },
  { value: 3, label: 'Kiosk' },
  { value: 4, label: 'Cinema' },
]

const STATUS_OPTIONS_FREIGHT = [
  { id: 17, text: 'For Quotation' },
  { id: 12, text: 'Quoted' },
  { id: 14, text: 'Pending Payment' },
  { id: 2, text: 'Accepted' },
  { id: 3, text: 'Picked up' },
  { id: 4, text: 'Arrived at Origin Port' },
  { id: 5, text: 'In Transit' },
  { id: 6, text: 'Arrived at Destination Port' },
  { id: 7, text: 'Out For Delivery' },
  { id: 8, text: 'Delivered' }
]

export default { STATE, MARKETS, STATUS_OPTIONS_FREIGHT }
