<template>
  <b-card class="profile-card">
    <b-row align-v="center">
      <b-col
        class="icon-section"
        cols="auto"
      >
        <feather-icon
          icon="UsersIcon"
          size="24"
          class="text-primary"
        />
      </b-col>
      <b-col class="details border-left">
        <div v-show="!editMode">
          <h4>{{ fullName.toUpperCase() }}</h4>
          <p>{{ userEmail }}</p>
        </div>
        <div
          v-show="editMode"
        >
          <validation-observer ref="emailNameForm">
            <validation-provider
              #default="{ errors }"
              name="Full Name"
              rules="required"
            >
              <b-form-input
                id="fullName"
                ref="fullName"
                v-model="form.fullName"
                :placeholder="$t('src.views.pages.inside.account-setting.components.nameandemail.enterPN')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                :placeholder="$t('src.views.pages.inside.account-setting.components.nameandemail.enterPE')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </div>
      </b-col>
      <b-col
        class="edit-section"
        cols="auto"
      >
        <a
          v-if="!editMode"
          href="#"
          @click.prevent="editFields"
        >{{ $t('src.views.pages.inside.account-setting.components.nameandemail.edit') }}</a>
        <a
          v-else-if="editMode"
          href="#"
          @click.prevent="saveFields"
        >{{ $t('src.views.pages.inside.account-setting.components.nameandemail.save') }}</a>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    fullName: {
      type: String,
      default: '',
    },
    userEmail: {
      type: String,
      default: '',
    },
    updateDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      options: {},
      form: {
        fullName: this.fullName,
        email: this.userEmail,
      },
      editMode: false,
      required,
      email,
    }
  },
  watch: {
    fullName(val) {
      this.form.fullName = val
    },
    userEmail(val) {
      this.form.email = val
    },
  },
  methods: {
    editFields() {
      this.editMode = !this.editMode
      this.$nextTick(() => {
        this.$refs.fullName.focus()
      })
    },
    async saveFields() {
      this.$refs.emailNameForm.validate().then(async success => {
        if (success) {
          this.$emit('update-details', this.form)
        }
      })
    },
  },
}
</script>
