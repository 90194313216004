<template>
  <b-card class="profile-card">
    <b-row align-v="center">
      <b-col
        class="icon-section"
        cols="auto"
      >
        <feather-icon
          icon="MapPinIcon"
          size="24"
          class="text-primary"
        />
      </b-col>
      <b-col class="details border-left">
        <h4>{{ $t('address') }}</h4>
        <div
          v-for="(address, index) in listOfAddress"
          v-show="!editMode"
          :key="index"
          class="mb-3"
        >
          <p>{{ address.name }}</p>
          <p>{{ address.phone }}</p>
          <p>{{ address.address1 }}</p>
          <p>{{ address.address2 }}</p>
          <span>{{ address.postcode }}</span><span>{{ address.area }}</span><span>{{ address.state }}</span><span>{{ address.country }}</span>
        </div>
        <validation-observer ref="editAddress">
          <b-form
            ref="editAddressForm"
            class="mt-1"
          >
            <div
              v-for="(address, index) in listOfAddress"
              v-show="editMode"
              :key="address.id"
            >
              <b-row>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    :name="`name`"
                    rules="required"
                  >
                    <b-form-input
                      :id="`name_${index}`"
                      v-model="listOfAddress[index].name"
                      :state="errors.length > 0 ? false:null"
                      :name="`name_${index}`"
                    />
                    <small class="text-danger">{{ errors[0] ? errors[0].replace(`_${index}`, '') : null }}</small>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    :name="`phone`"
                    rules="required|integer"
                  >
                    <b-form-input
                      :id="`phone_${index}`"
                      v-model="listOfAddress[index].phone"
                      :state="errors.length > 0 ? false:null"
                      :name="`phone_${index}`"
                    />
                    <small class="text-danger">{{ errors[0] ? errors[0].replace(`_${index}`, '') : null }}</small>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    :name="`address line 1`"
                    rules="required"
                  >
                    <b-form-input
                      :id="`address1_${index}`"
                      v-model="listOfAddress[index].address1"
                      :state="errors.length > 0 ? false:null"
                      :name="`address1_${index}`"
                    />
                    <small class="text-danger">{{ errors[0] ? errors[0].replace(`_${index}`, '') : null }}</small>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    :name="`address line 2`"
                    rules="required"
                  >
                    <b-form-input
                      :id="`address2_${index}`"
                      v-model="listOfAddress[index].address2"
                      :state="errors.length > 0 ? false:null"
                      :name="`address2_${index}`"
                    />
                    <small class="text-danger">{{ errors[0] ? errors[0].replace(`_${index}`, '') : null }}</small>
                  </validation-provider>
                  <div class="row">
                    <div class="col">
                      <validation-provider
                        #default="{ errors }"
                        :name="`postcode`"
                        rules="required"
                      >
                        <b-form-input
                          :id="`postcode_${index}`"
                          v-model="listOfAddress[index].postcode"
                          :state="errors.length > 0 ? false:null"
                          :name="`postcode_${index}`"
                        />
                        <small class="text-danger">{{ errors[0] ? errors[0].replace(`_${index}`, '') : null }}</small>
                      </validation-provider>
                    </div>
                    <div class="col">
                      <validation-provider
                        #default="{ errors }"
                        :name="`area`"
                        rules="required"
                      >
                        <b-form-input
                          :id="`area_${index}`"
                          v-model="listOfAddress[index].area"
                          :state="errors.length > 0 ? false:null"
                          :name="`area_${index}`"
                          :placeholder="$t('src.views.pages.inside.account-setting.components.address.enterA')"
                        />
                        <small class="text-danger">{{ errors[0] ? errors[0].replace(`_${index}`, '') : null }}</small>
                      </validation-provider>
                    </div>
                    <div class="col">
                      <validation-provider
                        #default="{ errors }"
                        name="State"
                        rules="required"
                      >
                        <select
                          :id="`state_${index}`"
                          v-model="listOfAddress[index].state"
                          class="custom-select"
                          :state="errors.length > 0 ? false:null"
                          :name="`state_${index}`"
                        >
                          <option
                            v-for="(value, i) in state"
                            :key="i"
                            :value="value.value"
                          >
                            {{ value.text }}
                          </option>
                        </select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <!-- <validation-provider
                        #default="{ errors }"
                        :name="`state`"
                        rules="required"
                      >
                        <b-form-input
                          :id="`state_${index}`"
                          v-model="listOfAddress[index].state"
                          :state="errors.length > 0 ? false:null"
                          :name="`state_${index}`"
                          :placeholder="$t('src.views.pages.inside.account-setting.components.address.enterS')"
                        />
                        <small class="text-danger">{{ errors[0] ? errors[0].replace(`_${index}`, '') : null }}</small>
                      </validation-provider> -->
                    </div>
                    <div class="col">
                      <validation-provider
                        #default="{ errors }"
                        :name="`country`"
                        rules="required"
                      >
                        <select
                          :id="`country_${index}`"
                          v-model="listOfAddress[index].country"
                          class="custom-select"
                          :name="`country_${index}`"
                        >
                          <option
                            value="Malaysia"
                            selected
                          >
                            Malaysia
                          </option>
                          <option value="Singapore">
                            Singapore
                          </option>
                        </select>
                        <small class="text-danger">{{ errors[0] ? errors[0].replace(`_${index}`, '') : null }}</small>
                      </validation-provider>
                    </div>
                  </div>
                  <hr
                    v-if="index !== (listOfAddress.length - 1)"
                    style="margin-bottom: 2rem !important"
                  >
                </b-col>
                <b-col
                  cols="auto"
                  class="edit-section d-flex flex-column justify-content-center align-items-center"
                >
                  <a
                    v-show="address.id !== defaultId"
                    href="#"
                    class="mb-2"
                    @click.prevent="deleteAddress(address.id)"
                  >{{ $t('remove') }}</a>
                  <a
                    href="#"
                    @click.prevent="saveFields"
                  >{{ $t('save') }}</a>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
        <h4
          v-show="!showAddressForm && !editMode"
          class="add-new-address"
          @click.prevent="handleAddNewAddress"
        >
          <FeatherIcon icon="PlusIcon" />
          {{ $t('add_new_address') }}
        </h4>
        <div class="address-wrapper">
          <div v-show="showAddressForm">
            <validation-observer ref="addAddress">
              <b-form
                ref="addressForm"
                class="auth-register-form mt-2"
                @submit.prevent="validationForm"
              >
                <b-form-group
                  :label="$t('name')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      name="name"
                      :placeholder="$t('name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  :label="$t('phone_number')"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="phone"
                      v-model="form.phone"
                      :state="errors.length > 0 ? false:null"
                      name="phone"
                      :placeholder="$t('enter_phone_number')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  :label="$t('address_line_1')"
                  label-for="address1"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('address_line_1')"
                    rules="required"
                  >
                    <b-form-input
                      id="address1"
                      v-model="form.address1"
                      :state="errors.length > 0 ? false:null"
                      name="address1"
                      :placeholder="$t('enter_address_line_1')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  :label="$t('address_line_2')"
                  label-for="address2"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('address_line_2')"
                    rules="required"
                  >
                    <b-form-input
                      id="address2"
                      v-model="form.address2"
                      :state="errors.length > 0 ? false:null"
                      name="address2"
                      :placeholder="$t('enter_address_line_2')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="row">
                  <div class="col">
                    <b-form-group
                      :label="$t('postcode')"
                      label-for="postcode"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Postal Code"
                        rules="required"
                      >
                        <b-form-input
                          id="postcode"
                          v-model="form.postcode"
                          :state="errors.length > 0 ? false:null"
                          name="postcode"
                          :placeholder="$t('enter_postcode')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col">
                    <b-form-group
                      :label="$t('area')"
                      label-for="area"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Area"
                        rules="required"
                      >
                        <b-form-input
                          id="area"
                          v-model="form.area"
                          :state="errors.length > 0 ? false:null"
                          name="area"
                          :placeholder="$t('enter_area')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col">
                    <b-form-group
                      :label="$t('state')"
                      label-for="state"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="State"
                        rules="required"
                      >
                        <select
                          id="state"
                          v-model="form.state"
                          :state="errors.length > 0 ? false:null"
                          name="area"
                          :placeholder="$t('enter_state')"
                          class="custom-select"
                        >
                          <option
                            v-for="(value, name, index) in state"
                            :key="index"
                            :value="value.value"
                          >
                            {{ value.text }}
                          </option>
                        </select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <!-- <validation-provider
                        #default="{ errors }"
                        name="State"
                        rules="required"
                      >
                        <b-form-input
                          id="state"
                          v-model="form.state"
                          :state="errors.length > 0 ? false:null"
                          name="area"
                          :placeholder="$t('src.views.pages.inside.account-setting.components.address.state')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider> -->
                    </b-form-group>
                  </div>
                  <div class="col">
                    <b-form-group
                      :label="$t('country')"
                      label-for="country"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="country"
                        rules="required"
                      >
                        <select
                          id="country"
                          v-model="form.country"
                          class="custom-select"
                        >
                          <option
                            value="Malaysia"
                            selected
                          >
                            Malaysia
                          </option>
                          <option value="Singapore">
                            Singapore
                          </option>
                        </select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>

                <!-- submit button -->
                <div class="row">
                  <div class="col">
                    <b-button
                      variant="primary"
                      block
                      type="submit"
                    >{{ $t('submit') }}</b-button>
                  </div>
                  <div class="col">
                    <b-button
                      variant="secondary"
                      block
                      type="button"
                      @click.prevent="handleCancel"
                    >{{ $t('cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-col>
      <b-col
        v-show="!showAddressForm && !editMode"
        class="edit-section "
        cols="auto"
      >
        <a
          v-if="!editMode"
          href="#"
          @click.prevent="editFields"
        >{{ $t('edit') }}</a>
        <!-- <a
          v-else-if="editMode"
          href="#"
          @click.prevent="saveFields"
        >{{ $t('src.views.pages.inside.account-setting.components.address.save') }}</a> -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BCard, BForm,
  BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { integer } from 'vee-validate/dist/rules'
import Constant from '@/common/Constant'

extend('integer', {
  ...integer,
  message: 'Please enter numbers only',
})

export default {
  components: {
    BCard,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
    updateDetails: {
      type: Function,
      default: () => {},
    },
    fullName: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    defaultId: {
      type: Number,
      default: null,
    },
    removeAddress: {
      type: Function,
      default: () => {},
    },
    getUpdatedUser: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      state: Constant.STATE,
      form: {},
      required,
      listOfAddress: [],
      showAddressForm: false,
      editMode: false,
      editAddressSuccess: [],
    }
  },
  watch: {
    addresses: {
      immediate: true, // forcing handler on initial status
      handler(val) {
        this.listOfAddress = val
      },
    },
  },
  methods: {
    validationForm() {
      const self = this
      this.$refs.addAddress.validate().then(success => {
        if (success) {
          const headers = {
            Accept: 'application/json',
          }
          const details = { ...self.form }
          axios.post('/insertAddress', details, { headers })
            .catch(err => {
              console.error(err)
            })
            .then(response => {
              this.listOfAddress.push(details)
              if (response.data?.status) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('new_address_added'),
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                })
                this.getUpdatedUser()
              }
            })
          this.showAddressForm = !this.showAddressForm
          this.$refs.addressForm.reset()
        }
      })
    },
    handleAddNewAddress() {
      this.showAddressForm = !this.showAddressForm
    },
    handleCancel() {
      this.showAddressForm = !this.showAddressForm
    },
    editFields() {
      this.editMode = !this.editMode
    },
    async saveFields() {
      this.$refs.editAddress.validate().then(async success => {
        if (success) {
          this.$emit('update-details', { addresses: this.listOfAddress })
        }
      })
    },
    async deleteAddress(addressId) {
      try {
        const response = await this.removeAddress(addressId)
        if (response === 'success') {
          this.editMode = !this.editMode
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
<style scoped>
  .address-wrapper {
  width: 650px;
}
</style>
